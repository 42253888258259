module.exports = {
  BY: "",
  CATEGORY: "categoria",
  COPYRIGHT: "Todos os direitos reservados",
  CATEGORIES: "Categorias",
  FEATURED: "Em Destaque",
  FOLLOW_US: "Siga a gente",
  LATEST_POSTS: "Últimos Posts",
  MORE_POSTS: "Mais Posts",
  POPULAR: "Popular",
  READ_MORE: "Leia mais",
}
