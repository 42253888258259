module.exports = {
  BY: "by",
  CATEGORY: "category",
  COPYRIGHT: "All Rights Reserved",
  CATEGORIES: "Categories",
  FEATURED: "Featured",
  FOLLOW_US: "Follow us",
  LATEST_POSTS: "Latest Posts",
  MORE_POSTS: "More Posts",
  POPULAR: "Popular",
  READ_MORE: "Read More",
}
